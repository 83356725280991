body {
  margin: 0;
  font-family: 'Gogoia Deco';
}

@font-face {
  font-family: 'Gogoia Deco';
  src: url('./fonts/gogoia-deco.ttf');
  font-weight: 300;
  font-style: normal;
}